/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { Themed } from 'theme-ui';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SanityContent from '../content/sanity-content';
//import { FlowerFaceComp } from '../home-page/faces';
import { useLangKey } from '../../utils/lang';
import ContentContainer from '../../gatsby-theme-catalyst-core/components/content-container';

const red = '#ff2fac';

const redShadow = {
    textShadow: `0 -1px 0 ${red}, 0 1px 0 ${red}, 1px 0 0 ${red}, -1px 0 0 ${red}, -3px 1px 0 ${red}`,
    color: 'white'
};

const BioPage = ({ location, pageContext, data }) => {
    const title = data.sanityMember.fullName
    const image = data.sanityMember.content.bioImage?.asset;
    const { intlContent } = data.sanityMember.content.bioContent;
    //const handleClick = useLetBlink()
    return (
        <Layout pathname={location.pathname} pageContext={pageContext}>
            <ContentContainer>
                <div sx={{ maxWidth: '800px', mx: 'auto' }}>
                    {image && <GatsbyImage objectFit='contain' sx={{ mt: 0, mb: 4, maxHeight:'70vh' }} image={getImage(image)} />}
                    <Themed.h2 sx={{
                        color: 'black',
                        fontFamily: 'Oswald',
                        fontWeight: "bold",
                        textShadow: 'unset',
                        textTransform: 'unset',
                        mb:4
                    }}>{title}</Themed.h2>

                    {intlContent && <SanityContent intlContent={intlContent} />}
                </div>
            </ContentContainer>
        </Layout>
    );
};

export const query = graphql`
query BioQuery($langCode: String!, $id: String!) {
    sanityMember(id: {eq: $id}) {
      content {
        bioContent {
          intlContent(langCode: $langCode) {
            raw
            rawDe
            rawEn
            rawEs
            rawFr
          }
        }
        bioImage {
          asset {
            gatsbyImageData
          }
        }
      }
      fullName
    }
  }
`;

export default BioPage;
